import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import ReactSlick from 'react-slick';
import Chevron from 'Assets/svg/arrowRight.svg';
import s from './SlickSlider.module.scss';

const SlickSlider = ({items, Card, modifier, progressBar, customOptions, useSwipe, swipeDuration}) => {
    // Slider and container must be seperated, otherwise checking contains won't work
    const containerRef = useRef();
    const sliderRef = useRef();

    const initialSlide = _.get(customOptions, 'initialSlide', 0);
    const [active, setActive] = useState(initialSlide);
    const [swiping, setSwiping] = useState(false);

    const showDots = !progressBar && customOptions.dots;

    const defaultOptions = {
        dots: progressBar || showDots,
        infinite: false,
        arrows: !showDots,
        dotsClass: progressBar ? s['SlickSlider__Progress'] : s['SlickSlider__Nav'],
        prevArrow: <Button isNext={false} />,
        nextArrow: <Button isNext={true} />,
        responsive: null,
        slide: 'li',
        swipeToSlide: true,
        initialSlide: initialSlide,
        slidesToScroll: 1,
        slidesToShow: 1,
        ...customOptions,
    };

    const appendProgress = () => (
        <div>
            <div
                className={s['SlickSlider__ProgressBar']}
                style={{
                    width: `${((active+1) / items.length * 100).toPrecision(4)}%`,
                }}
            />
        </div>
    );

    const getDotCount = () => {
        const spec = defaultOptions;
        if (spec.infinite) {
            return Math.ceil(items.length / spec.slidesToScroll);
        } else {
            return Math.ceil((items.length - spec.slidesToShow) / spec.slidesToScroll) + 1;
        }
    };

    const appendDots = (dots) => {
        const dotCount = getDotCount();
        const isPrevDisabled = active === 0;
        const isNextDisabled = active === dotCount-1;
        const prevOnClick = isPrevDisabled ? null : () => sliderRef.current.slickPrev();
        const nextOnClick = isNextDisabled ? null : () => sliderRef.current.slickNext();
        return (
            <div>
                <Button
                    isNext={false}
                    className={s['SlickSlider__Button--Dots']}
                    disabled={isPrevDisabled}
                    onClick={prevOnClick}
                />
                <ul className={s['SlickSlider__Dots']}>{dots}</ul>
                <Button
                    isNext={true}
                    className={s['SlickSlider__Button--Dots']}
                    disabled={isNextDisabled}
                    onClick={nextOnClick}
                />
            </div>
        );
    };

    const paginateDots = (index) => {
        const dotClasses = classNames(
            [s['SlickSlider__Dot']],
            {[s['SlickSlider__Dot--Active']]: index === active},
        );
        return (
            <li className={dotClasses}>
                <button type="button" aria-label={index+1} />
            </li>
        );
    };

    const options = {
        ...defaultOptions,
        beforeChange: (oldIndex, newIndex) => {
            setSwiping(true);
            setActive(newIndex);
            setTimeout(() => {
                setSwiping(false);
            }, swipeDuration);
        },
        ...(progressBar && {appendDots: appendProgress}),
        ...(showDots && {
            appendDots: appendDots,
            customPaging: paginateDots,
        }),
    };

    useEffect(() => {
        const swipe = (e) => {
            if(e.target === containerRef.current || containerRef.current.contains(e.target)) {
                const upOrDown = e.deltaY >= 1 || e.deltaY <= -1;
                if(!upOrDown && e.deltaX >= 1) {
                    sliderRef.current.slickNext();
                } else if(!upOrDown && e.deltaX <= -1) {
                    sliderRef.current.slickPrev();
                }
            }
        };

        if(useSwipe && containerRef && containerRef.current) {
            window.addEventListener('mousewheel', swipe);
            return () => {
                window.removeEventListener('mousewheel', swipe);
            };
        }
    }, [containerRef, useSwipe]);

    const classes = classNames(
        [s['SlickSlider']],
        {[s['SlickSlider--Default']]: !progressBar},
        {[s['SlickSlider--ProgressBar']]: progressBar},
        {[s['SlickSlider--Swiping']]: swiping},
        {[s[`SlickSlider--${_.upperFirst(modifier)}`]]: modifier},
    );

    return (
        <div className={classes} ref={containerRef}>
            <ReactSlick className={s['SlickSlider__Slider']} {...options} ref={sliderRef}>
                {items.map((item, index) => {
                    // Style must be set here, not in the card
                    const width = _.get(item, 'width', null);
                    const style = {
                        ...(width !== null && {width: width}),
                    };
                    return (
                        <div
                            className={s['SlickSlider__Item']}
                            style={style}
                            key={index}
                        >
                            <Card
                                {...item}
                                isSwiping={swiping}
                                isActive={index === active}
                            />
                        </div>
                    );
                })}
            </ReactSlick>
        </div>
    );
};

SlickSlider.propTypes = {
    items: PropTypes.array.isRequired,
    Card: PropTypes.elementType.isRequired,
    modifier: PropTypes.string,
    progressBar: PropTypes.bool,
    useSwipe: PropTypes.bool,
    swipeDuration: PropTypes.number,
    customOptions: PropTypes.object,
};

SlickSlider.defaultProps = {
    items: [],
    Card: null,
    modifier: '',
    progressBar: false,
    useSwipe: false,
    swipeDuration: 500,
    customOptions: {},
};

const Button = (props) => {
    const {isNext, className: extraClasses, style, onClick, disabled} = props;
    const {t} = useTranslation();
    const title = isNext ? t('slickSlider.next') : t('slickSlider.previous');
    const classes = classNames(
        [s['SlickSlider__Button']],
        {[s['SlickSlider__Button--Next']]: isNext},
        {[s['SlickSlider__Button--Prev']]: !isNext},
        extraClasses,
        {'slick-disabled': disabled},
    );
    return (
        <button className={classes} style={{...style}} onClick={onClick}>
            <Chevron />
            <span className="sr-only">{title}</span>
        </button>
    );
};

Button.propTypes = {
    isNext: PropTypes.bool,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    isNext: false,
    className: '',
    style: {},
    disabled: false,
    onClick: null,
};

export default SlickSlider;
