import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ParallaxProvider } from 'react-scroll-parallax';
import * as Components from 'Components/ModulesComponents';
import ParallaxShape from 'Components/ParallaxShape';
import s from './Modules.module.scss';

const Modules = ({components, showParallax, initialSize, forceSize, extendLeftRightModules}) => {
    // All modules that should have parallax need to be in this component

    const otherSize = initialSize === 'Large' ? 'Small' : 'Large';

    const leftRight = [
        'Entrances', 'NewsList', 'ContactPersons', 'ImageBlock', 'ArticleList',
        'MemberModule', 'ContentModule', ...extendLeftRightModules,
    ];
    const middle = ['ExternalFeed', 'FAQModule', 'FormModule'];

    // Using count to make sure every third left or right shape is large
    let countSize = 0;
    let side = 'Left';
    let sideMiddle = false;
    let size = '';
    const clone = [...components];
    let prevComponent = '';
    const list = clone.map((c, index) => {
        const nextI = index+1 < clone.length ? index+1 : null;
        const nextC = nextI === null ? null : clone[nextI].componentName;
        const nextIsMiddle = middle.includes(nextC);

        const isMiddle = middle.includes(c.componentName);
        const isLeftRight = leftRight.includes(c.componentName);

        // Skip before and after any middle
        let skip = sideMiddle || (!isMiddle && nextC !== null && nextIsMiddle);

        // Skip parallax if two contactPersons are after each other
        if(prevComponent === 'ContactPersons' && c.componentName === 'ContactPersons') {
            skip = true;
            prevComponent = null;
        } else {
            prevComponent = c.componentName;
        }

        // Force previouse component to left so the next parallax starts with right instead
        if(prevComponent === 'Search') {
            side = 'Left';
        }

        if(isLeftRight && !skip) {
            side = side === 'Left' ? 'Right' : 'Left';
            sideMiddle = false;
        } else if(isMiddle) {
            if(!sideMiddle) {
                sideMiddle = true;
            } else { // Prevent two middles being displayed after each other
                sideMiddle = false;
                skip = true;
            }
        } else {
            sideMiddle = false;
        }

        if(isLeftRight && !isMiddle && !skip) {
            size = countSize % 3 === 0 ? initialSize : otherSize;
            countSize++;

            // Force size to small for some components 
            if(c.componentName === 'ContactPersons') {
                size = 'Small';
            }
        }

        if(forceSize !== null) {
            size = forceSize;
        }

        const r = Math.floor(Math.random() * 10) * 2;
        return {
            component: c,
            show: showParallax && !skip && (isLeftRight || isMiddle),
            side: sideMiddle ? 'Middle' : side,
            size: size,
            offset: isMiddle ? [-10 + r, 40 + r] : (
                size === 'Large' ? [-20 + r, 10 + r] : [-10 + r, 30 + r]
            ),
        };
    });

    return (
        <div className={s['Modules']}>
            <ParallaxProvider>
                <div className={s['Modules__List']}>
                    {list.map(({component, side, size, show, offset}, index) => {
                        return (
                            <Item
                                key={index}
                                {...component}
                                showParallax={show}
                                side={side}
                                size={size}
                                offset={offset}
                            />
                        );
                    })}
                </div>
            </ParallaxProvider>
        </div>
    );
};

Modules.propTypes = {
    components: PropTypes.array.isRequired,
    showParallax: PropTypes.bool,
    initialSize: PropTypes.string,
    forceSize: PropTypes.string,
    extendLeftRightModules: PropTypes.array,
};

Modules.defaultProps = {
    components: [],
    showParallax: true,
    initialSize: 'Small',
    forceSize: null,
    extendLeftRightModules: [],
};

const Item = ({componentName, componentProps, showParallax, side, size, offset}) => {
    const Component = Components[componentName];
    if (!Component) {
        return null;
    }

    const classes = classNames(
        [s['Modules__Item']],
        [s[`Modules__Item--${componentName}`]],
        {[s[`Modules__Item--${side}`]]: side},
        {[s[`Modules__Item--${size}`]]: size},
    );

    const y = offset.map((i) => i + '%');
    return (
        <div className={classes}>
            {showParallax &&
                <ParallaxShape
                    y={y}
                    side={side}
                    size={size}
                    modifier={componentName}
                />
            }

            <Component {...componentProps} isModule={true} />
        </div>
    );
};

Item.propTypes = {
    componentName: PropTypes.string.isRequired,
    componentProps: PropTypes.object,
    scrollPosition: PropTypes.number,
    showParallax: PropTypes.bool,
    side: PropTypes.string,
    size: PropTypes.string,
    offset: PropTypes.array,
};

Item.defaultProps = {
    componentName: '',
    componentProps: {},
    scrollPosition: 0,
    showParallax: false,
    side: '',
    size: '',
    offset: [],
};

export default Modules;