import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import Image from 'Components/Image';
import s from './CardHighlight.module.scss';

const CardHighlight = ({title, excerpt, url, image}) => {
    const classes = classNames(
        [s['CardHighlight']],
        {[s['CardHighlight--NoImage']]: _.isEmpty(image)},
        {[s['CardHighlight--HasImage']]: !_.isEmpty(image)},
    );

    // Match card size instead of image size for preventing pixelated image
    const sizes = [
        '(min-width: 768px) 50vw',
        '100vw',
    ];

    // This is used in Highlights, and since it doesn't have a title, these cards need to be h2

    /* Add image to link so the title attribute of img is shown on hover */
    return (
        <article className={classes}>
            <a className={s['CardHighlight__Link']} href={url}>
                <span className="sr-only">{title}</span>

                {!_.isEmpty(image) &&
                    <div className={s['CardHighlight__Placeholder']}>
                        <div className={s['CardHighlight__Image']}>
                            <Image {...image} sizes={sizes} useCover={true} />
                        </div>
                    </div>
                }
            </a>

            {!_.isEmpty(image) && // Image placeholder
                <div className={s['CardHighlight__Image']} />
            }

            <div className={s['CardHighlight__Content']}>
                <h2 className={s['CardHighlight__Title']}>{title}</h2>

                {excerpt &&
                    <div className={s['CardHighlight__Text']}>{excerpt}</div>
                }
            </div>
        </article>
    );
};

CardHighlight.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.object,
};

CardHighlight.defaultProps = {
    title: '',
    excerpt: '',
    url: '',
    image: {},
};

export default CardHighlight;
