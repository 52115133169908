import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './LinkModule.module.scss';

const LinkModule = ({title, text, links, showFull, smallTitle}) => {
    const classes = classNames(
        [s['LinkModule']],
        {[s['LinkModule--FullWidth']]: showFull},
        {[s['LinkModule--ContentWidth']]: !showFull},
        {[s['LinkModule--LargeTitle']]: !smallTitle},
        {[s['LinkModule--SmallTitle']]: smallTitle},
        {[s['LinkModule--One']]: links.length === 1},
        {[s['LinkModule--Two']]: links.length === 2},
        {[s['LinkModule--Three']]: links.length > 2},
    );
    return (
        <div className={classes}>
            <div className={s['LinkModule__Wrap']}>
                <div className={s['LinkModule__Content']}>
                    <h2 className={s['LinkModule__Title']}>
                        {title}
                    </h2>
                    {text &&
                        <div className={s['LinkModule__Text']}>
                            {text}
                        </div>
                    }
                    <ul className={s['LinkModule__List']}>
                        {links.map(({title, url, target}, index) => (
                            <li className={s['LinkModule__Item']} key={index}>
                                <a
                                    className={s['LinkModule__Button']}
                                    href={url}
                                    target={target}
                                >
                                    {title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

LinkModule.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    links: PropTypes.array,
    showFull: PropTypes.bool,
    smallTitle: PropTypes.bool,
};

LinkModule.defaultProps = {
    title: '',
    text: '',
    links: [],
    showFull: true,
    smallTitle: false,
};

export default LinkModule;
