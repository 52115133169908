import React from 'react';
import PropTypes from 'prop-types';
import GutenbergContent from 'Components/GutenbergContent';
import s from './GutenbergModule.module.scss';

const GutenbergModule = ({gutenbergContent}) => {
    return (
        <div className={s['GutenbergModule']}>
            <GutenbergContent
                {...gutenbergContent}
                modifier="Modules"
            />
        </div>
    );
};

GutenbergModule.propTypes = {
    gutenbergContent: PropTypes.object,
};

GutenbergModule.defaultProps = {
    gutenbergContent: {},
};

export default GutenbergModule;
