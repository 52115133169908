import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import Mail from 'Assets/svg/mail.svg';
import Phone from 'Assets/svg/phone.svg';
import Image from 'Components/Image';
import s from './CardPerson.module.scss';

const CardPerson = ({name, careerTitle, mail, phone, image, allowPortraitLayout}) => {
    const {t} = useTranslation();

    const sizes = [
        '(min-width: 375px) 140px',
        '100px',
    ];

    const classes = classNames(
        [s['CardPerson']],
        {[s['CardPerson--AllowPortrait']]: allowPortraitLayout},
    );
    const parsedPhone = phone.replace(/^(?:(\+|00)?46|0)?/, '0046').replace(/[-\s]/g, '');
    return (
        <div className={classes}>
            {!_.isEmpty(image) &&
                <div className={s['CardPerson__Image']}>
                    <Image {...image} sizes={sizes} useCover={true} />
                </div>
            }
            <div className={s['CardPerson__Content']}>
                <span className={s['CardPerson__Label']}>{t('cardPerson.label')}</span>
                
                <h3 className={s['CardPerson__Name']}>{name}</h3>

                {careerTitle &&
                    <span className={s['CardPerson__CareerTitle']}>{careerTitle}</span>
                }
                {mail &&
                    <a
                        className={classNames(s['CardPerson__Link'], s['CardPerson__Link--Mail'])}
                        href={`mailto:${mail}`}
                        title={mail}
                    >
                        <Mail />
                        <span className={s['CardPerson__LinkText']}>{t('cardPerson.mail')}</span>
                    </a>
                }
                {phone &&
                    <a
                        className={classNames(s['CardPerson__Link'], s['CardPerson__Link--Phone'])}
                        href={`tel:${parsedPhone}`}
                        title={parsedPhone}
                    >
                        <Phone />
                        <span className={s['CardPerson__LinkText']}>{phone}</span>
                    </a>
                }
            </div>
        </div>
    );
};

CardPerson.propTypes = {
    name: PropTypes.string.isRequired, 
    careerTitle: PropTypes.string, 
    mail: PropTypes.string,
    phone: PropTypes.string, 
    image: PropTypes.object,
    allowPortraitLayout: PropTypes.bool,
};

CardPerson.defaultProps = {
    name: '', 
    careerTitle: '', 
    mail: '', 
    phone: '', 
    image: {},
    allowPortraitLayout: true,
};

export default CardPerson;
