import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import s from './ContentModule.module.scss';

const ContentModule = ({title, text}) => {
    if(_.isEmpty(title) && _.isEmpty(text)) {
        return null;
    }

    return (
        <div className={s['ContentModule']}>
            <div className={s['ContentModule__Wrap']}>
                <div className={s['ContentModule__Content']}>
                    <h2 className={s['ContentModule__Title']}>{title}</h2>

                    {text &&
                        <div
                            className={s['ContentModule__Text']}
                            dangerouslySetInnerHTML={{__html: text}}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

ContentModule.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};

ContentModule.defaultProps = {
    title: '',
    text: '',
};

export default ContentModule;
