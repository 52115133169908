/* global isJest */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Parallax} from 'react-scroll-parallax';
import s from './ParallaxShape.module.scss';

const ParallaxShape = ({y, side, size, modifier, children, className}) => {
    if(typeof(isJest) !== 'undefined' && isJest === true) {
        return null;
    }

    // Make sure to add ParallaxProvider to a wrapper container
    // Modifier is usually the wrapper component name
    const classes = classNames(
        [s['ParallaxShape']],
        {[s[`ParallaxShape--${modifier}`]]: modifier},
        {[s[`ParallaxShape--${side}`]]: side},
        {[s[`ParallaxShape--${size}`]]: size},
        className,
    );
    return (
        <Parallax className={classes} translateY={y}>
            <div className={s['ParallaxShape__Inner']}>
                {children}
            </div>
        </Parallax>
    );
};

ParallaxShape.propTypes = {
    y: PropTypes.array,
    side: PropTypes.string,
    size: PropTypes.string,
    modifier: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

ParallaxShape.defaultProps = {
    y: [],
    side: '',
    size: '',
    modifier: '',
    children: null,
    className: '',
};

export default ParallaxShape;
