import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Image from 'Components/Image';
import s from './QuoteModule.module.scss';

const QuoteModule = ({
    quote,
    author,
    image,
}) => {
    const sizes = [
        '(min-width: 1680px) 943px',
        '(min-width: 1440px) 839px',
        '(min-width: 1280px) 712px',
        '(min-width: 1024px) 528px',
        '(min-width: 768px) 477px',
        '(min-width: 500px) 444px',
        '100vw',
    ];
    return (
        <div className={s['QuoteModule']}>
            <div className={s['QuoteModule__Wrap']}>
                {!_.isEmpty(image) &&
                    <div className={s['QuoteModule__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
                {quote &&
                    <div className={s['QuoteModule__Quote']}>
                        <blockquote>
                            <p>{quote}</p>
                            {author && <cite>{author}</cite>}
                        </blockquote>
                    </div>
                }
            </div>
        </div>
    );
};

QuoteModule.propTypes = {
    quote: PropTypes.string.isRequired,
    author: PropTypes.string,
    image: PropTypes.object,
};

QuoteModule.defaultProps = {
    quote: '',
    author: '',
    image: {},
};

export default QuoteModule;
