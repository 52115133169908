import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Image from 'Components/Image';
import s from './ImageBlock.module.scss';

const ImageBlock = ({ 
    title, 
    text, 
    link, 
    image,
}) => {
    const sizes = [
        '(min-width: 1680px) 1440px',
        '(min-width: 1440px) 1280px',
        '(min-width: 1280px) 1140px',
        '(min-width: 1024px) 808px',
        '(min-width: 768px) 20px',
        '100vw',
    ];
    return (
        <div className={s['ImageBlock']}>
            <div className={s['ImageBlock__Wrap']}>
                <div className={s['ImageBlock__Image']}>
                    <Image {...image} sizes={sizes} useCover={true} />
                </div>
                <div className={s['ImageBlock__Card']}>
                    <h2 className={s['ImageBlock__Title']}>{title}</h2>
                    
                    {!_.isEmpty(text) &&
                        <p className={s['ImageBlock__Text']}>{text}</p>
                    }
                    
                    {!_.isEmpty(link) &&
                        <Link {...link} />
                    }
                </div>
            </div>
        </div>
    );
};

ImageBlock.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
};

ImageBlock.defaultProps = {
    title: '',
    text: '',
    link: {},
    image: {},
};

const Link = ({url, title, target}) => (
    <a className={s['ImageBlock__Link']} href={url} target={target}>{title}</a>
);

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
};

Link.defaultProps = {
    title: '',
    url: '',
    target: '',
};

export default ImageBlock;
