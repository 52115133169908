import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import Image from 'Components/Image';
import s from './ContentImage.module.scss';

const ContentImage = ({image = {}, title = '', text = '', link = {}, isRight = false}) => {
    const sizes = [
        '(min-width: 1680px) 742px',
        '(min-width: 1440px) 640px',
        '(min-width: 1280px) 570px',
        '(min-width: 1024px) 404px',
        '(min-width: 768px) 360px',
        '100vw',
    ];
    const classes = classNames(
        [s['ContentImage']],
        {[s['ContentImage--IsLeft']]: !isRight},
        {[s['ContentImage--IsRight']]: isRight},
    );
    return (
        <div className={classes}>
            <div className={s['ContentImage__Wrap']}>
                {!isRight &&
                    <div className={s['ContentImage__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }

                <div className={s['ContentImage__Content']}>
                    <h2 className={s['ContentImage__Title']}>{title}</h2>
                    
                    {!_.isEmpty(text) &&
                        <p className={s['ContentImage__Text']}>{text}</p>
                    }
                    
                    {!_.isEmpty(link) &&
                        <Link {...link} compTitle={title} />
                    }
                </div>

                {isRight &&
                    <div className={s['ContentImage__Image']}>
                        <Image {...image} sizes={sizes} useCover={true} />
                    </div>
                }
            </div>
        </div>
    );
};

ContentImage.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    isRight: PropTypes.bool,
};

const Link = ({url = '', title = '', target = '', compTitle = ''}) => {
    const { t } = useTranslation();
    const useSrOnly = title === 'Läs mer';
    return (
        <a
            className={s['ContentImage__Button']}
            href={url}
            target={target}
        >
            {title}
            {useSrOnly &&
                <span className="sr-only">{t('contentImage.srLinkText', {title: compTitle})}</span>
            }
        </a>
    );
};

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
};

export default ContentImage;
