import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import classNames from 'classnames';
import CardNews from 'Components/CardNews';
import s from './NewsList.module.scss';

const NewsList = ({title, featuredItems, items, link}) => {
    return (
        <div className={s['NewsList']}>
            <div className={s['NewsList__Wrap']}>
                <div className={s['NewsList__Header']}>
                    <h2 className={s['NewsList__Title']}>{title}</h2>

                    {!_.isEmpty(link) &&
                        <Link {...link} />
                    }
                </div>

                <div className={s['NewsList__List']}>

                    {featuredItems && featuredItems.map((item, index) => (
                        <div
                            key={index}
                            className={classNames(s['NewsList__Item'], s['NewsList__Item--IsLarge'])}
                        >
                            <CardNews {...item} isLarge={true} />
                        </div>
                    ))}

                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={classNames(s['NewsList__Item'], s['NewsList__Item--IsSmall'])}
                        >
                            <CardNews {...item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

NewsList.propTypes = {
    title: PropTypes.string.isRequired,
    featuredItems: PropTypes.array,
    items: PropTypes.array.isRequired,
    link: PropTypes.object.isRequired,
};

NewsList.defaultProps = {
    title: '',
    featuredItems: [],
    items: [],
    link: {},
};

const Link = ({url, title, target}) => {
    const {t} = useTranslation();
    const text = !_.isEmpty(title) ? title : t('newsList.link');
    return (
        <a className={s['NewsList__Link']} href={url} target={target}>{text}</a>
    );
};

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
};

Link.defaultProps = {
    title: '',
    url: '',
    target: '',
};


export default NewsList;
