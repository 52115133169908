import dynamic from 'next/dynamic';

// Only add components that will be needed in flexible content modules
import ArticleList from './ArticleList';
import ContactPersons from './ContactPersons';
import ContentImage from './ContentImage';
import ContentModule from './ContentModule';
import Entrances from './Entrances';
// import ExternalFeed from './ExternalFeed';
import FAQModule from './FAQModule';
// import FormModule from './FormModule';
import GutenbergModule from './GutenbergModule';
import Highlights from './Highlights';
import ImageBlock from './ImageBlock';
import LinkModule from './LinkModule';
import MemberModule from './MemberModule';
// import Newsletter from './Newsletter';
import NewsList from './NewsList';
import QuoteModule from './QuoteModule';
import Search from './Search';
// import Snippet from './Snippet';

// Lazy load components not needed for SSR and require js to work
const ExternalFeed = dynamic(() => import('./ExternalFeed'), {ssr: false});
const FormModule = dynamic(() => import('./FormModule'), {ssr: false});
const Newsletter = dynamic(() => import('./Newsletter'), {ssr: false});
const Snippet = dynamic(() => import('./Snippet'), {ssr: false});

export {
    ArticleList,
    ContactPersons,
    ContentImage,
    ContentModule,
    Entrances,
    ExternalFeed,
    FAQModule,
    FormModule,
    GutenbergModule,
    Highlights,
    ImageBlock,
    LinkModule,
    MemberModule,
    Newsletter,
    NewsList,
    QuoteModule,
    Search,
    Snippet,
};
