import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardEntrance from 'Components/CardEntrance';
import s from './Entrances.module.scss';

const Entrances = ({items, isSmall}) => {
    const classes = classNames(
        [s['Entrances']],
        {[s['Entrances--IsLarge']]: !isSmall},
        {[s['Entrances--IsSmall']]: isSmall},
    );
    return (
        <div className={classes}>
            <div className={s['Entrances__Wrap']}>
                <div className={s['Entrances__List']}>
                    {items.map((item, index) => (
                        <div className={s['Entrances__Item']} key={index}>
                            <CardEntrance {...item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Entrances.propTypes = {
    items: PropTypes.array,
    isSmall: PropTypes.bool,
};

Entrances.defaultProps = {
    items: [],
    isSmall: false,
};

export default Entrances;
