import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardPerson from 'Components/CardPerson';
import s from './ContactPersons.module.scss';

const ContactPersons = ({label, title, persons}) => {

    const classes = classNames(
        [s['ContactPersons']],
        {[s['ContactPersons--One']]: persons.length === 1},
        {[s['ContactPersons--Two']]: persons.length === 2},
        {[s['ContactPersons--Three']]: persons.length > 2},
    );

    return (
        <div className={classes}>
            <div className={s['ContactPersons__Wrap']}>
                <div className={s['ContactPersons__Heading']}>
                    {label &&
                        <span className={s['ContactPersons__Label']}>{label}</span>
                    }

                    <h2 className={s['ContactPersons__Title']}>{title}</h2>
                </div>

                {persons && 
                    <div className={s['ContactPersons__List']}>
                        {persons.map((person, i) => (
                            <div className={s['ContactPersons__Item']} key={i}>
                                <CardPerson
                                    {...person}
                                    allowPortraitLayout={persons.length > 2}
                                />
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

ContactPersons.propTypes = {
    label: PropTypes.string,
    title: PropTypes.string.isRequired,
    persons: PropTypes.array.isRequired,
};

ContactPersons.defaultProps = {
    label: '',
    title: '',
    persons: [],
};

export default ContactPersons;
