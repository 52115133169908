import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import Number1 from 'Assets/img/number1--salmon.svg';
import Number2 from 'Assets/img/number2--salmon.svg';
import Number3 from 'Assets/img/number3--salmon.svg';
import Entrances from 'Components/Entrances';
import Image from 'Components/Image';
import s from './MemberModule.module.scss';

const MemberModule = ({title, steps, entrances}) => {
    return (
        <div className={s['MemberModule']}>
            <div className={s['MemberModule__Wrap']}>
                {title &&
                    <h2 className={s['MemberModule__Title']}>{title}</h2>
                }
                {steps && 
                    <div className={s['MemberModule__Steps']}>
                        {steps.map((step, index) => (
                            <Step 
                                {...step}
                                key={index}
                                index={index}
                            />
                        ))}
                    </div>
                }
                {entrances &&
                    <div className={s['MemberModule__Entrances']}>
                        <Entrances {...entrances} isSmall={true} />
                    </div>
                }
            </div>
        </div>
    );
};

MemberModule.propTypes = {
    title: PropTypes.string,
    steps: PropTypes.array.isRequired,
    entrances: PropTypes.object,
};

MemberModule.defaultProps = {
    title: '',
    steps: [],
    entrances: {},
};

const Step = ({title, text, index}) => {
    const { t } = useTranslation();
    const images = {
        0: Number1,
        1: Number2,
        2: Number3,
    };
    const image = images[index];
    return (
        <div className={s['MemberModule__Step']}>
            <span className={s['MemberModule__StepNumber']}>
                <Image
                    src={image.src}
                    width={image.width}
                    height={image.height}
                    alt={t('memberModule.step', {index: index+1})}
                />
            </span>
            {title &&
                <h3 className={s['MemberModule__StepTitle']}>{title}</h3>
            }
            {text &&
                <p className={s['MemberModule__StepText']}>{text}</p>
            }
        </div>
    );
};

Step.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
    index: PropTypes.number.isRequired,
};

Step.defaultProps = {
    title: '',
    text: '',
    image: {},
    index: 1,
};

export default MemberModule;
