import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import s from './FAQModule.module.scss';

const FAQModule = ({title, items, link}) => {
    return (
        <div className={s['FAQModule']}>
            <div className={s['FAQModule__Wrap']}>
                <div className={s['FAQModule__Header']}>
                    <h2 className={s['FAQModule__Title']}>{title}</h2>

                    {!_.isEmpty(link) &&
                        <Link {...link} />
                    }
                </div>

                <div className={s['FAQModule__List']}>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={s['FAQModule__Item']}
                        >
                            <Link
                                {...item}
                                target="_blank"
                                isQuestion={true}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

FAQModule.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    link: PropTypes.object,
};

FAQModule.defaultProps = {
    title: '',
    items: [],
    link: {},
};

const Link = ({url, title, target, isQuestion}) => {
    const classes = classNames(
        [s['FAQModule__Link']],
        {[s['FAQModule__Link--Question']]: isQuestion},
    );
    return (
        <a
            className={classes}
            href={url}
            target={target}
            rel="noreferrer noopener"
        >{title}</a>
    );
};

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
    isQuestion: PropTypes.bool,
};

Link.defaultProps = {
    title: '',
    url: '',
    target: '',
    isQuestion: false,
};

export default FAQModule;
