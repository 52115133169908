import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import s from './CardEntrance.module.scss';

const CardEntrance = ({title, excerpt, link}) => {

    // This is used in Entracnes, and since it doesn't have a title, these cards need to be h2
    return (
        <div className={s['CardEntrance']}>
            {!_.isEmpty(link) &&
                <Link {...link} />
            }
            <div className={s['CardEntrance__Content']}>
                <h2 className={s['CardEntrance__Title']}>{title}</h2>

                {excerpt &&
                    <p className={s['CardEntrance__Text']}>{excerpt}</p>
                }

                <div className={s['CardEntrance__Footer']}>
                    {link &&
                        <button className={s['CardEntrance__Button']}>{link.title}</button>
                    }
                </div>
            </div>
        </div>
    );
};

CardEntrance.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    link: PropTypes.object,
};

CardEntrance.defaultProps = {
    title: '',
    excerpt: '',
    link: {},
};

const Link = ({url, title, target}) => (
    <a className={s['CardEntrance__Link']} href={url} target={target}>
        <span className="sr-only">{title}</span>
    </a>
);

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
};

Link.defaultProps = {
    title: '',
    url: '',
    target: '',
};

export default CardEntrance;
