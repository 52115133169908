import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CardHighlight from 'Components/CardHighlight';
import SlickSlider from 'Components/SlickSlider';
import s from './Highlights.module.scss';

const Highlights = ({items}) => {
    const isSlider = items.length > 2;

    const classes = classNames(
        [s['Highlights']],
        {[s['Highlights--Slider']]: isSlider},
    );

    return (
        <div className={classes}>
            <div className={s['Highlights__Wrap']}>
                {isSlider ? (
                    <SlickSlider
                        items={items}
                        Card={CardHighlight}
                        modifier="Highlights"
                        customOptions={{
                            slidesToShow: 2,
                            dots: true,
                            responsive: [
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 1,
                                    },
                                },
                            ],
                        }}
                    />
                ) : (
                    <div className={s['Highlights__List']}>
                        {items.map((item, index) => (
                            <div key={index} className={s['Highlights__Item']}>
                                <CardHighlight {...item} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

Highlights.propTypes = {
    items: PropTypes.array.isRequired,
};

Highlights.defaultProps = {
    items: [],
};

export default Highlights;
